.author-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $lighten-gray;
  .author-img {
    width: 120px;
    height: 120px;
    margin-right: 30px;
    border-radius: 100%;
  }
  .author-desc {
    h2 {
      margin: 0 0 10px;
    }
    p {
      margin: 0 0 10px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      display: inline-block;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      a {
        font-size: 18px;
        color: $dark-gray;
        &:hover {
          opacity: .8;
        }
      }
    }
    .keybase {
      img {
        width: 18px;
        margin-bottom: -2px;
      }
    }
  }
}
