.pagination {
  margin-bottom: 30px;
  .previous {
    float: left;
  }
  .next {
    float: right;
  }
  .previous, .next {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: $dark-gray;
    &:hover {
      opacity: .8;
      color: $dark-pink;
    }
  }
}
