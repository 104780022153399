/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .main-header {
    .logo {
      a {
        font-size: 24px;
      }
    }
  }

  .post-card-box {
    li {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

.recent-box {
  .recent-list {
    .recent-item {
      position: relative;
      display: inline-block;
      width: 49%;
      height: 150px;
      margin-left: 0.5%;
      margin-bottom: 0.5%;
      span {
        position: absolute;
        font-size: 12px;
        left: 2.5%;
        bottom: 10%;
        color: $white;
      }
    }
  }
}

}

@media only screen and (max-width: 480px) {
  .wrapper {
    max-width: 95%;
  }

  .page-content {
    margin-top: -50px;
    padding: 15px;
  }

  .header-page {
    margin-bottom: 20px;
    h1 {
      font-size: 27px;
      margin: 0 0 10px;
    }
    .page-date {
      font-size: 14px;
    }
  }

  .main-header {
    .logo {
      a {
        font-size: 21px;
      }
    }
  }
  .menu-icon,
  .menu-icon-close,
  .search-icon,
  .search-icon-close {
    font-size: 16px;
  }

.page-image {
  height: 380px;
}

.recent-box {
  .recent-list {
    .recent-item {
      position: relative;
      display: inline-block;
      width: 99%;
      height: 150px;
      margin-left: 0.5%;
      margin-bottom: 0.5%;
      span {
        position: absolute;
        font-size: 12px;
        left: 2.5%;
        bottom: 10%;
        color: $white;
      }
    }
  }
}

.newsletter {
  .newsletter-box {
    .subscribe-btn {
      width: 80px;
      height: 40px;
      font-size: 12px;
    }
  }
}

.about-body {
  .contact-list {
    li {
      display: inline-block;
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
      a {
        display: block;
        color: $light-gray;
        font-size: 16px;
        text-decoration: none;
        padding: 5px;
        transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82);
        &:hover {
          color: $dark-pink;
          transform: scale(1.3);
        }
      }
    }
  }
}

.author-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .author-img {
    height: 100px;
    width: 100px;
    margin: 0 0 10px;
  }
  .author-desc {
    h2 {
      margin: 0 0 10px;
    }
    p {
      margin: 0 0 15px;
    }
  }
}

}
