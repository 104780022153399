.cv-page {
    table {
        width: 100%;
        border:none;
        border-collapse: collapse;
        .company {
            text-transform: uppercase;
            font-size: 120%;
        }

        td {
            border-bottom: 0px;
            border-right: 1px solid black;
            &:first-child {
                font-weight: bold;
                border-left: none;
                width: 20%;
                text-align: right;
                padding-right: 10px;
                vertical-align: top;
            }
            &:last-child {
                padding-left: 10px;
                border-right: none;
                width: 80%;
            }
        }

        .emptyline {
            td {
                border: none;
            }
        }
    }
}