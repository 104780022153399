.blog-tags {
  margin: 100px 0 100px;
  h1 {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    margin: 0 0 30px;
    color: $dark-purple;
  }
  .tags {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        display: inline-block;
        color: $dark-purple;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
  .tag-list {
    span {
      a {
        display: inline-block;
        color: $dark-pink;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
          opacity: .8;
        }
      }
    }
    small {
      color: $dark-purple;
    }
  }
}
