.small-wrapper {
  width: 700px;
  max-width: 90%;
  margin: 0 auto;
}

.about-container {
  margin: 100px 0 100px;
}

.about-header {
  position: relative;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    height: 1px;
    width: 80px;
    background-color: $dark-purple;
  }
  .author-image-container {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto 30px;
  }
  .subtitle {
    color: $dark-purple;
    font-weight: 300;
    margin: 0;
  }
}

.about-body {
  .contact-list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      a {
        display: block;
        color: $light-gray;
        font-size: 21px;
        text-decoration: none;
        padding: 5px;
        transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82);
        &:hover {
          color: $dark-pink;
          transform: scale(1.3);
        }
      }
    }
    .keybase {
      img {
          width: 18px;
      }
    }
  }
}
