.main-footer {
  padding: 15px 0;
  background-color: #efefef;

  .copyright {
    text-align: center;
    color: $dark-purple;
    font-size: 16px;
  }
}
